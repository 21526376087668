import React from "react"

import Layout from "../components/Layout"
import { HospitalTable } from "../components/HospitalTable"

const Admissions = ({ location }) => (
  <Layout pathname={location.pathname} title={"New hospital admissions"}>
    <h3 className="title is-3">Nations</h3>
    <HospitalTable
      areaType="nation"
      field="newAdmissions"
      label="New admissions"
      per100kLabel="7-day new admissions"
    />
    <h3 className="title is-3">NHS regions</h3>
    <HospitalTable
      areaType="nhsregion"
      field="newAdmissions"
      label="New admissions"
      per100kLabel="7-day new admissions"
    />
  </Layout>
)

export default Admissions
